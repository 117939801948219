import { Form, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { useState, useEffect, useCallback } from 'react';
import Popup from 'components/Popup';
import { useMutation } from 'react-query';
import { CONFIG_SITE, USER_ROLE } from 'constants/index';
import Image from 'next/image';
import { useAuth } from 'utils/context/auth';
import { useTranslation } from 'next-i18next';
import { Button, RequiredAsterisk } from '@utils/ui';
import { EyeIcon, EyeOffIcon, AtSymbolIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

const ModalLogin = (props) => {
  const { open, onCancel, confirmLoading } = props;
  const [form] = Form.useForm();
  const { login, user, isLoading } = useAuth();
  const { t } = useTranslation('login-page');
  const [isShowPassword, setIsShowPassword] = useState(false);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const loginMutation = useMutation((data) => login(data), {
    onError: (error) => {
      toast.error(error.data.message || 'Oops, terjadi kesalahan');
    },
  });

  const onSubmit = async (data) => loginMutation.mutateAsync(data);

  const renderEyeIcons = () => {
    if (!isShowPassword) {
      return <EyeIcon className="w-[20px] h-[20px]  text-[#bbbbbb]" />;
    }
    return <EyeOffIcon className="w-[20px] h-[20px]  text-[#bbbbbb]" />;
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      width={475}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      // onOk={() => form.submit()}
      // confirmLoading={
      //   (mutationUploadImage.isLoading && !mutationUploadImage.isError) || confirmLoading
      // }
    >
      <div className='text-center mt-[10px]'>
        <h1 className="font-semibold text-lg xl:text-2xl text-grayScale05 mb-[10px] flex items-center gap-4 justify-center">
          Selamat datang di
          <span className="relative w-[109px] h-[38px]">
            <Image src="/xooply-logo.png" layout="fill" alt="Logo" />
          </span>
        </h1>
        <p className="text-sm text-[#555] font-normal leading-[150%]">
        Masuk ke akun Anda untuk menikmati <br/>
        pengalaman belanja yang lebih nyaman.
        </p>
        <form className="mt-[30px] text-left" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-[20px] font-semibold text-sm relative">
            <span className="block">
              <span className="text-grayScale05 font-semibold text-sm">{t('Email')}</span>
            </span>
            <input
              type="text"
              {...register('username', {
                required: true,
              })}
              className="mt-[4px] px-[21px] py-[10px] bg-white border shadow-sm border-gray-300 placeholder-[#bbbbbb] text-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Masukkan email Anda"
            />
            <span className='absolute right-[0px] top-[28px] p-[10px] z-10'>
              <AtSymbolIcon className="w-[20px] h-[20px]  text-[#bbbbbb]" />
            </span>
            <span className="mt-[4px] text-red-500 text-xs font-light">
              {errors.username?.type === 'required' && 'Email wajib diisi'}
            </span>
          </div>
          <div className="relative">
            <span className="block">
              <span className="text-grayScale05 font-semibold text-sm">Kata Sandi</span>
            </span>
            <input
              maxLength={95}
              type={isShowPassword ? 'text' : 'password'}
              {...register('password', {
                required: true,
              })}
              className="mt-[4px] px-[21px] py-[10px] bg-white border shadow-sm border-gray-300 placeholder-[#bbbbbb] text-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Masukkan kata sandi Anda"
            />
            <button
              type="button"
              className="cursor-pointer absolute right-[0px] top-[28px] p-[10px] z-10"
              onClick={() => {
                setIsShowPassword(!isShowPassword);
              }}
            >
              {renderEyeIcons()}
            </button>
            <span className="mt-[4px] text-red-500 text-xs font-light">
              {errors.password?.type === 'required' && 'Kata Sandi wajib diisi'}
            </span>
          </div>
          <a
            href="/forgot-password"
            className="text-xs capitalize font-semibold block mt-[10px] text-theme-primary-main hover:text-theme-primary-main bg-clip-text bg-gradient-to-br from-primary to-theme-primary-lighter"
          >
            Lupa Kata Sandi?
          </a>
          <Button
            htmlType="submit"
            block
            variant="primary"
            className="mt-[24px] mb-[24px]"
            size="large"
            loading={loginMutation.isLoading && !loginMutation.isError}
          >
            Masuk
          </Button>

          <div className="text-center">
            <span className="text-sm text-center font-light">Belum Memiliki Akun? </span>
            <a
              href={'/register'}
              className="text-sm text-theme-primary-main font-semibold cursor-pointer gtm-register-anchor"
            >
              Daftar
            </a>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalLogin;
