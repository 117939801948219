import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { isArray } from 'lodash';
const ModalConfirm = ({ message, onOk, onCancel, okText, cancelText, confirmLoading, title, ...rest }) => {
    return (React.createElement(Modal, { onCancel: (event) => {
            if (Array.isArray(onCancel)) {
                onCancel[1](event);
            }
            else {
                onCancel(event);
            }
        }, title: title ?? null, width: 500, footer: React.createElement("div", { className: "flex justify-end gap-y-2 flex-wrap" },
            React.createElement(Button, { variant: "outlined", onClick: (event) => {
                    if (Array.isArray(onCancel)) {
                        onCancel[0](event);
                    }
                    else {
                        onCancel(event);
                    }
                } }, cancelText || 'Batal'),
            React.createElement(Button, { variant: "primary", onClick: (event) => onOk(event), loading: confirmLoading, disabled: isArray(okText) ? okText[1] : false }, isArray(okText) ? okText[0] : okText ?? 'Ok')), ...rest },
        title && React.createElement("div", { className: "text-center" }, message),
        !title && typeof message === 'string' ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "text-lg font-semibold min-h-[120px]" }, message))) : (!title && message)));
};
export default ModalConfirm;
