import useGetHeaderHeight from 'hooks/useGetHeaderHeight';
import PropTypes from 'prop-types';
import BackButton from 'components/BackButton/BackButton';
import { useRouter } from 'next/router';
import Header from './Header';
import Footer from './Footer';

const MainLayout = ({
  isHomepage,
  children,
  hasBack,
  onBack,
  isLayoutDisabled = false,
  isHeaderFull = false,
}) => {
  const headerHeight = useGetHeaderHeight();
  const router = useRouter();

  return (
    <div className="relative bg-grayScale02 flex flex-col min-h-screen">
      <Header
        headerHeight={headerHeight}
        isHomepage={isHomepage}
        isLayoutDisabled={isLayoutDisabled}
        isHeaderFull={isHeaderFull}
      />
      <main style={{ paddingTop: headerHeight }} className="flex-1">
        {/* <AspectRatio ratio={16 / 9}  maw={400} mx="auto">
          <Overlay color="#000" opacity={0.40}  />
        </AspectRatio> */}
        {!!hasBack && (
          <section className="section-container flex flex-col gap-2" style={{ paddingBottom: 0 }}>
            <div>
              <BackButton
                onClick={() => {
                  if (onBack) {
                    return onBack();
                  }
                  return router.back();
                }}
              />
            </div>
          </section>
        )}
        {children}
      </main>
      <Footer isHomepage={isHomepage} isLayoutDisabled={isLayoutDisabled} />
    </div>
  );
};

MainLayout.propTypes = {
  isHomepage: PropTypes.bool,
  hasBack: PropTypes.bool,
  onBack: PropTypes.func,
};

MainLayout.defaultProps = {
  isHomepage: false,
  hasBack: false,
  onBack: null,
};

export default MainLayout;
