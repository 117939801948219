/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import { NextSeo } from 'next-seo';
import { Row, Col, Popover } from 'antd';
import MainLayout from 'layouts/MainLayout';
import useCategoryStore from 'stores/category.store';
import { useQuery } from 'react-query';
import { getDisplayProductSection, getHomepageData } from 'api/homepage';
import { cloneDeep } from 'lodash';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { CONFIG_SITE } from 'constants/index';
import { useRouter } from 'next/router';
import RegisterPopup from './views/RegisterPopup';
import HeroBanner from './_components/HeroBanner';
import HeroCategory from './_components/HeroCategory';
import HeroCategoryMobile from './_components/HeroCategoryMobile';
import RecommendationCarousel from './_components/RecommendationCarousel';
import HighlightProductCarousel from './_components/HighlightProductCarousel';
import SeoFooter from './_components/SeoFooter';
import mockHomepageData from '../../../public/mock-data/homepage';
import BuyerTestimonial from './_components/BuyerTestimonial';
import { parseCookies, setCookie } from 'nookies';
import { Modal } from '@utils/ui';
import { XCircleIcon } from '@heroicons/react/solid';
import ProductSectionCarousel from './_components/ProductSectionCarousel';
import { LoadingOutlined } from '@ant-design/icons';
import Image from 'next/image';

const HomePage = () => {
  const router = useRouter();
  const cookies = parseCookies();
  const isXooply = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.xooply;

  const { hero_banner, recommendation_banner, middle_banner, highlighted_products } =
    mockHomepageData;

  const { categories } = useCategoryStore();
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [openModalRegisEwallet, setOpenModalRegisEwallet] = useState(true);

  const onOpenRegisterPopup = () => setIsOpenRegister(true);
  const onCloseRegisterPopup = () => setIsOpenRegister(false);

  const homepageHighlightedProductsQuery = useQuery(['homepage-highlighted-products'], () =>
    getHomepageData(),
  );

  const highlightedProducts = useMemo(() => {
    const data = cloneDeep(highlighted_products);

    const { data: homepageHighlightedProducts } = homepageHighlightedProductsQuery || {};

    const perLimit = CONFIG_SITE?.HIGHLIGHTED_PRODUCT_PER_SECTION_LIMIT ?? 6;

    const firstHighlight = [...(homepageHighlightedProducts?.data || [])].splice(0, perLimit);
    const secondHightlight = [...(homepageHighlightedProducts?.data || [])].splice(
      perLimit,
      perLimit,
    );

    if (data.length >= 0) {
      data[0].content.products = [...firstHighlight].map((item) => ({
        ...item,
        image: {
          path: item.imagePath,
        },
        sellerStore: {
          storeAddress: {
            cityName: item.storeCity,
            districtName: item.storeDistrict,
          },
        },
      }));
    }

    if (data.length > 1) {
      data[1].content.products = [...secondHightlight].map((item) => ({
        ...item,
        image: {
          path: item.imagePath,
        },
        sellerStore: {
          storeAddress: {
            cityName: item.storeCity,
            districtName: item.storeDistrict,
          },
        },
      }));
    }

    return data;
  }, [homepageHighlightedProductsQuery.data, highlighted_products]);

  const { data: displayProductSectionQuery, isLoading } = useQuery(
    ['display-product-section'],
    () => getDisplayProductSection(),
  );

  const displayProductSectionData = displayProductSectionQuery?.data;

  const productSectionList = CONFIG_SITE.PRODUCT_SECTION ? displayProductSectionData : null;

  // eslint-disable-next-line no-shadow
  const getDisplayData = (productSectionList, highlightedProducts) => {
    const maxDisplayCount = 3;

    const filteredHighlightedProducts =
      highlightedProducts?.filter((product) =>
        product?.content?.products?.every((p) => p.isProductLimitation !== false),
      ) || [];

    const productSections = productSectionList?.slice(0, maxDisplayCount) || [];
    const remainingCount = maxDisplayCount - productSections.length;
    const highlights = filteredHighlightedProducts?.slice(0, remainingCount) || [];
    return { productSections, highlights };
  };

  console.log('produk limit', highlightedProducts);

  const { productSections, highlights } = getDisplayData(productSectionList, highlightedProducts);

  const firstProductSection = productSections[0] || highlights[0];
  const otherProductSection = [
    ...productSections.slice(1),
    ...highlights.slice(productSections.length > 0 ? 0 : 1),
  ];

  const setSeoTitle = `Situs Pengadaan Barang dan Jasa Terlengkap di Indonesia | ${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND}`;
  const setSeoDesc = `Belanja barang dan jasa sekarang makin mudah di sini. Segera dapatkan suplai barang dan jasa yang berkualitas dari ${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND}! ✓Transparan ✓Aman ✓Terpercaya`;

  const handleCancel = () => {
    const now = new Date();

    setOpenModalRegisEwallet(false);
    setCookie(null, 'flagModal', 'true', {
      maxAge: 24 * 60 * 60,
      path: '/',
    });
  };

  return (
    <MainLayout isHomepage>
      <NextSeo
        title={setSeoTitle}
        description={setSeoDesc}
        openGraph={{
          description: setSeoDesc,
          title: setSeoTitle,
        }}
        additionalMetaTags={[
          {
            name: 'ahrefs-site-verification',
            content: 'a7ca62dabc86f08f28ce577146361ecf5b504edab112ec99788f65baefbf6d7f',
          },
        ]}
      />
      <section className="relative">
        <div className="absolute inset-0 bg-gray-gradient opacity-[0.25]" />
        <section className="section-container">
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24} lg={8} xl={6} className="hidden lg:block lg:h-[215px] xl:h-[325px]">
              <HeroCategory categories={categories} />
            </Col>
            <Col xs={24} lg={16} xl={18} flex="auto" className="lg:h-[215px] xl:h-[325px]">
              <HeroBanner data={hero_banner.content} />
            </Col>
            <Col xs={24} className="block lg:hidden pt-4">
              <HeroCategoryMobile categories={categories} />
            </Col>
          </Row>
        </section>
        <div className="container mx-auto" />
      </section>

      <section className="section-container">
        <RecommendationCarousel recommendationBanners={recommendation_banner} />
      </section>

      {/* {highlightedProducts[0] && (
        <section className="section-container">
          <HighlightProductCarousel productSection={highlightedProducts[0]} />
        </section>
      )} */}

      {isLoading && (
        <div className="flex items-center justify-center h-[456px]">
          <LoadingOutlined />
        </div>
      )}

      {!isLoading && firstProductSection && (
        <section className="section-container">
          {CONFIG_SITE.PRODUCT_SECTION && productSections.length > 0 ? (
            <ProductSectionCarousel productSection={firstProductSection} />
          ) : (
            <HighlightProductCarousel productSection={firstProductSection} />
          )}
        </section>
      )}

      <section className="section-container">
        <div className="pt-8 pb-4">
          {middle_banner && (
            <div
              role="button"
              tabIndex={0}
              className="flex h-[120px] lg:h-[320px] w-full bg-no-repeat bg-cover cursor-pointer"
              onClick={() => router.push(middle_banner?.content?.redirectUrl)}
            >
              <img
                src={middle_banner.content.image.path}
                alt=""
                className="w-full object-left-top object-cover"
              />
            </div>
          )}
        </div>
      </section>
      {/* <section className="section-container">
        <div className="py-8">
          <h6 className="text-2xl font-semibold mb-5">Belum memiliki Akun?</h6>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <div
                className="flex flex-row rounded-[18px] py-5 items-center shadow-md border bg-white cursor-pointer"
                onClick={() => {
                  router.push('/register/retail');
                }}
              >
                <div className="px-5">
                  <img src="/assets/icons/icon-cart-home.svg" alt="icon-cart" />
                </div>
                <div className="flex items-center justify-between w-full pr-5">
                  <p className="text-lg text-grayScale04 ">
                    Daftar sebagai <span className="font-semibold">Pembeli</span>
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="#E41B1B"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div
                className="flex flex-row rounded-[18px] py-5 items-center border shadow-md bg-white cursor-pointer"
                onClick={() => window.open(`${ENV.SELLER_URL}/register`, '_blank')}
              >
                <div className="px-5">
                  <img src="/assets/icons/icon-shop.svg" alt="icon-shop" />
                </div>
                <div className="flex justify-between w-full pr-5">
                  <p className="text-lg text-grayScale04 ">
                    <a href={`${ENV.SELLER_URL}/register`} target="_blank">
                      Daftar sebagai <span className="font-semibold">Penjual</span>
                    </a>
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="#E41B1B"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

      {/* {highlightedProducts[1] && (
        <section className="section-container">
          <HighlightProductCarousel productSection={highlightedProducts[1]} />
        </section>
      )} */}

      {!isLoading &&
        otherProductSection.map((product, index) =>
          CONFIG_SITE.PRODUCT_SECTION && productSections.length > index + 1 ? (
            <section className="section-container">
              <ProductSectionCarousel key={`product-${index + 1}`} productSection={product} />
            </section>
          ) : (
            <section className="section-container">
              <HighlightProductCarousel key={`highlight-${index + 1}`} productSection={product} />
            </section>
          ),
        )}

      {CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE?.buyer_testimonial && (
        <section className="section-container">
          <BuyerTestimonial
            data={CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE?.buyer_testimonial}
          />
        </section>
      )}

      <RegisterPopup isShow={isOpenRegister} close={onCloseRegisterPopup} />

      {isXooply && (
        <Modal
          width={600}
          open={openModalRegisEwallet && cookies.flagModal === undefined}
          closable={false}
          destroyOnClose
          footer={null}
          className="modal-no-space modal-transparent pb-0"
        >
          <div className="flex items-center justify-center">
            <XCircleIcon
              width="33"
              height="33"
              className="text-black cursor-pointer top-[5px] right-[58px] absolute z-50"
              onClick={() => handleCancel()}
            />
            <Image
              onClick={() => router.push('/register')}
              src={
                CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE?.popup_banner?.content?.image?.path
              }
              alt="Daftar dan Menangkan Reward Saldo E-Wallet"
              className="cursor-pointer rounded-lg"
              width={500}
              height={500}
              unoptimized
              priority
            />
          </div>
        </Modal>
      )}

      {CONFIG_SITE?.MAINSITE?.HOMEPAGE_SEO_TEXT && <SeoFooter />}
    </MainLayout>
  );
};

export default HomePage;
