import { apiRequest, API_PREFIX } from 'utils/request.utils';
import { parseCookies } from 'nookies';

const cookies = parseCookies();

export const getHomepageData = async () => {
  try {
    const isUserLoggedIn = !!cookies.token;
    const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: isUserLoggedIn }).get(
      '/homepage',
      { params: { limit: 12 } },
    );

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDisplayProductSection = async () => {
  try {
    const isUserLoggedIn = !!cookies.token;
    const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: isUserLoggedIn }).get(
      '/product-section/display',
    );
    return response.data;
  } catch (e) {
    throw e.message;
  }
};

export default {
  getHomepageData,
  getDisplayProductSection,
};
